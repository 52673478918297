import React from "react";

import DocsHelmet from "components/docs/shared-components/DocsHelmet";
import HeaderBar from "components/docs/layout/HeaderBar";
import CrossGuideLinks from "components/docs/navigation/CrossGuideLinks";
import { PageProps, Link } from "gatsby";
import styled from "styled-components";
import DocumentationSection from "components/docs/DocumentationSection";
import TwoColumnCenteredSection from "components/docs/sections/TwoColumnCenteredSection";
import { StaticPageContainer } from "components/docs/shared-components/PageContainers";
import MapFieldsOnLink from "../../../assets/images/docs/guides/field-mapping/MapFieldsOnLink.svg";
import FieldMappingsOnLink from "../../../assets/images/docs/guides/field-mapping/FieldMappingsOnLink.svg";
import CreateFieldMappingDashboardPage from "../../../assets/images/docs/guides/field-mapping/CreateFieldMappingDashboardPage.svg";
import AddFieldMappingOptions from "../../../assets/images/docs/guides/field-mapping/AddFieldMappingOptions.svg";
import LINKS from "components/docs/navigation/links";

const TwoColumnSection = styled(TwoColumnCenteredSection).attrs({ padding: 20 })`
  margin-top: 16px;
`;

/**
 * Creates a Target Fields page for Field Mapping guide
 */
const CreateFieldMappingForLinkedAccount = ({
  location,
}: PageProps<unknown, unknown, Record<string, unknown> | null>) => {
  const title = "Mapping for a Linked Account";
  const description = "Create Field Mappings specific to each Linked Account";

  return (
    <StaticPageContainer style={{ paddingBottom: "0" }}>
      <DocsHelmet title={title} description={description} />
      <HeaderBar title={title} subtitle={description} />
      <DocumentationSection title="Overview">
        <p>
          <p>There are three ways to create Linked Account-specific Field Mappings:</p>
          <ul>
            <li>In each Linked Account's page of your Merge dashboard</li>
            <li>Via API</li>
            <li>In Merge Link</li>
          </ul>
        </p>
      </DocumentationSection>
      <hr className="my-9" />
      <DocumentationSection title="Create a Field Mapping in Merge dashboard">
        <p>
          This method does not involve your end users and is done completely within your Merge
          Dashboard.
        </p>
        <p>
          We recommend disabling end-user created target fields and making your organization’s
          target fields not mappable in Link to prevent your users from seeing Field Mapping in the
          linking flow. See our{" "}
          <a href="/supplemental-data/field-mappings/target-fields" target="_blank">
            Target Fields doc
          </a>{" "}
          to learn how to configure your target fields in Link.
        </p>
        <p>
          In the Field Mapping section of your user’s Linked Account page, click <b>+Mapping</b> in
          the upper right, then select the type of mapping you want to create.
        </p>
        <div className="w-full flex items-center justify-center my-9">
          <CreateFieldMappingDashboardPage />
        </div>
        <p>
          If you choose to <b>Map additional Linked Account-specific target field</b> or{" "}
          <b>Override Merge Common Model field</b>, you will be prompted to select a Remote Field,
          then name a new target field for that Linked Account or select a Merge field to create the
          mapping.
        </p>
        <p>
          If you choose to <b>Map existing organization-wide target fields</b>, you will see all of
          your organization's unmapped target fields for each Common Model. Select the Remote Fields
          on the right to create mappings. You can add and edit these target fields in{" "}
          <a href="https://app.merge.dev/configuration/field-mappings/target-fields">
            Advanced Configuration
          </a>
          .
        </p>
        <div className="w-full flex items-center justify-center mt-6">
          <AddFieldMappingOptions />
        </div>
      </DocumentationSection>
      <hr className="my-9" />
      <DocumentationSection title="Creating a Field Mapping via API">
        <p>
          You can create a new Field Mapping for a Linked Account by using the endpoint{" "}
          <Link to={LINKS.MERGE_FIELD_MAPPING_CREATE_ENDPOINT.linkTo}>
            {LINKS.MERGE_FIELD_MAPPING_CREATE_ENDPOINT.text}
          </Link>
          . You can update an existing mapping using the endpoint{" "}
          <Link to={LINKS.MERGE_FIELD_MAPPING_PATCH_ENDPOINT.linkTo}>
            {LINKS.MERGE_FIELD_MAPPING_PATCH_ENDPOINT.text}
          </Link>
          .
        </p>
        <p>
          See our <Link to={LINKS.MERGE_FIELD_MAPPING_ENDPOINTS.linkTo}>API reference</Link> for all
          Field Mapping endpoints and details (base URL varies by category).
        </p>
      </DocumentationSection>
      <hr className="mt-6 mb-9" />
      <DocumentationSection
        title="Let your users create Field Mappings in Merge Link"
        className="mt-5 mb-9"
      >
        <p>
          If you want your end users to create a Field Mapping themselves, you can define a{" "}
          <a
            href="https://app.merge.dev/configuration/field-mappings/target-fields"
            target="_blank"
            rel="noreferrer"
          >
            target field
          </a>{" "}
          in your Merge dashboard and configure it to appear as mappable in Merge Link. See our{" "}
          <a target="_blank" href="/supplemental-data/field-mappings/target-fields">
            Target Fields doc
          </a>{" "}
          to learn how to configure your target fields in Link.
        </p>

        <TwoColumnSection
          sectionWidth={12}
          columnA={
            <>
              <p className="mt-9">
                After your user authenticates their integration in your Merge Link component, they
                will be prompted to map fields.
              </p>
              <ul>
                <li>
                  If they select <b>Skip for now</b> they will be able to map fields later by
                  re-authenticating their integration.
                </li>
              </ul>
              <ul>
                <li>
                  If they select <b>Map fields</b>, your user will begin the process of mapping
                  their integration&apos;s fields to your targets.
                </li>
              </ul>
            </>
          }
          columnB={
            <div className="flex flex-col h-full">
              <div className="demo-img-frame my-9 mb-6 p-9 w-95">
                <MapFieldsOnLink height={360} className="mb-6" />
              </div>
              <p className="z-10 -mt-20 flex items-center justify-center">
                This is the first screen your user will see before they begin Field Mapping.
              </p>
            </div>
          }
        />
        <TwoColumnSection
          sectionWidth={12}
          columnA={
            <>
              <p className="mt-9">
                After selecting <b>Map fields</b>, your user will select a Remote Field for each of
                your fields from the dropdown of their integration&apos;s fields.
              </p>
              <p>
                If you have enabled user-created target fields for a Common Model in your Merge
                dashboard, your users can click <b>+ Mapping</b> in Merge Link to create new target
                fields for Linked Account-specific mappings.
              </p>
              <p>
                These target fields and mappings are configurable in the Field Mapping page of your
                user's Linked Account but will not appear in Merge Link for your other users.
              </p>
              <p>
                Your users can edit their Field Mappings by returning to Merge Link and
                re-authenticating their integration.
              </p>
            </>
          }
          columnB={
            <div className="flex flex-col h-full">
              <div className="demo-img-frame my-9 mb-6 p-9 w-95">
                <FieldMappingsOnLink height={360} className="mb-6" />
              </div>
              <p className="z-10 -mt-20 flex items-center justify-center">
                This is the screen your user will see after selecting Map fields.
              </p>
            </div>
          }
        />
      </DocumentationSection>
      <CrossGuideLinks location={location} usesQuaternaryLinks />
    </StaticPageContainer>
  );
};

export default CreateFieldMappingForLinkedAccount;
